import { FC, ReactElement, useEffect, useState } from 'react';
import FileService from '../../../services/FileService';
import { dataAttributeProps } from '../../../utils/ComponentUtils';
import { useImageCache } from '../../../contexts/ImageCacheContext';

type UploadedImageProps = {
  fileId: string;
  className?: string;
  placeholder?: ReactElement;
};

const UploadedImage: FC<UploadedImageProps> = (props) => {
  const { fileId, className, placeholder } = props;
  const { getImage, setImage } = useImageCache();
  const [image, setLocalImage] = useState<string | null>(null);

  useEffect(() => {
    setLocalImage(null);

    const cachedImage = getImage(fileId);
    if (cachedImage) {
      setLocalImage(cachedImage);
      return;
    }

    FileService.getFile(fileId).then((fileBlob) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string;
        setLocalImage(base64String);
        setImage(fileId, base64String);
      };
      reader.readAsDataURL(fileBlob);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileId]); // Runs when fileId changes

  return image ? <img src={image} className={className} {...dataAttributeProps(props)} alt="" /> : placeholder ? placeholder : <></>;
};

export default UploadedImage;
